import gql from 'graphql-tag';

const anioTableQuery = gql`
    query anios($numberItems: Int!, $numberPage: Int!, $fieldOrder: String!) {
        anios(first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: DESC}]) {
            data{
                id,
                nombre,
                activo
            }  
            paginatorInfo{
                currentPage
                lastItem
                hasMorePages
                lastPage
            }
        }
    }
`

const queries = { anioTableQuery };

export default queries;

<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>      
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Años'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.nombre}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <template>
                                                   
                                            <v-tooltip bottom v-if="!item.cerrado">
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-if="!item.cerrado">
                                                <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="loading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!loading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="anio" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Año"
                                                    :items="itemsAnio"
                                                    item-text="valor"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="anio.nombre"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Anios';
import Notify from '@/plugins/notify';
import anioApi from "../api/anio";
import gFunctions from '@/js/global_functions';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: "Nombre",
                    name: "nombre",
                    filterable: true,
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            anio       : {
                id            : 0,
                nombre        : ""
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            loading       : false,
            isSaving        : false,
            imagen          : '',
            rol             : null,
            datosLogin      : null,
            nombre          : null,
            itemsAnio           : [],
            
        }
    },
    methods: {
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            //this.$apollo.queries.anios;
        },
        async abrirModal(accion, data = null){
            await this.resetValues();
            this.anio.nombre = new Date().getFullYear().toString();
            this.accion     = accion;
            this.loading  = true;
            
            if(accion == "add"){
                this.tituloModal = "Registrar año.";
                this.imagen     = "/static/modal/actualizar_incidencia.svg";
                
            } else {
                this.tituloModal = "Actualizar año.";
                this.imagen     = "/static/modal/actualizar_incidencia.svg";
                this.anio.id          = data.id;
                this.anio.nombre      = data.nombre.toString();
            }

            this.loading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.anio = {
                id            : 0,
                nombre        : ""
            };

            this.$nextTick(() => {
               if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }

                this.nombre = null;
            });
        },
        eliminar(data){
            
            Notify.Alert(
                "¿Estás seguro de eliminar este año?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    anioApi.deleteAnio(data.id).then(response => {
                        Notify.Success("Operación exitosa", "El año ha sido eliminado satisfactoriamente.");
                        this.$apollo.queries.anios.refetch();
                    })
                    .catch(err => {
                        gFunctions.errorParse(err.response.data.error);
                        this.isLoading = false;
                    });
                },
                null,
                true,
                true
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                this.isLoading = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                this.anio.nombre.toString();
                if(this.accion === "add") {
                    anioApi.addAnio(this.anio)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El año se guardó satisfactoriamente.");
                        this.$apollo.queries.anios.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
                else if(this.accion === "update") {
                    anioApi.updateAnio(this.anio)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El año se actualizó satisfactoriamente.");
                        this.$apollo.queries.anios.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
            });
        },
        cargaInicial(){
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            let anios = [];
            let año = 2000;
            
            for(let n=0;n<100;n++){
                anios.unshift({id:año.toString(),valor:año.toString()});
                año = año +1;
            }

            this.itemsAnio = anios;
        },
        redireccionar(){
            this.$router.push('/dashboard');
        }
    },
    created() {
        this.cargaInicial();
    },
     mounted(){
        this.$apollo.queries.anios.skip = false;
    },
    apollo: {
        anios: {
            query       : queries.anioTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading = true;
                return {
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip(){
                return true;
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.tableData  = this.anios;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

    .anio_actual td{
    /*  color: #002438; */
        color: #24d40d !important;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
        line-height: 17px;
        text-align: left;
    }
</style>

import Vue from 'vue';

export default{
    
    updateAnio(parametros){
        return Vue.axios.put("catalogo/anios", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteAnio(id){
        return Vue.axios.delete("catalogo/anios/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addAnio(parametros){
        return Vue.axios.post("catalogo/anios", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}